import './App.css';
import React, { Component } from 'react';
import Web3 from "web3";
import wallet from './assets/wallet.png';

let allPositionsFront = [];
let account;
let i = 0;
let contract;
let token_contract;
let owner;
let usdtAmount = 25000000000;
let web3mm;
let accounts;
let buy;

let ABI = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_trader",
				"type": "address"
			}
		],
		"name": "checkMargin",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "checkMarginCalls",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "key",
				"type": "uint256"
			}
		],
		"name": "closePosition",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_trader",
				"type": "address"
			}
		],
		"name": "computeProfitAndLoss",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_assetName",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "_initialPrice",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "_pricefeed",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_token",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "trader",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "int256",
				"name": "positionSize",
				"type": "int256"
			}
		],
		"name": "PositionChecked",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "trader",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "int256",
				"name": "profitOrLoss",
				"type": "int256"
			}
		],
		"name": "ProfitAndLossComputed",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "int256",
				"name": "_positionSize",
				"type": "int256"
			}
		],
		"name": "trade",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "trader",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "int256",
				"name": "positionSize",
				"type": "int256"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "price",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "margin",
				"type": "uint256"
			}
		],
		"name": "TradeEvent",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_newPrice",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_newRate",
				"type": "uint256"
			}
		],
		"name": "updatePriceAndRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "updatePriceFromChainlink",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "withdrawFunds",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_trader",
				"type": "address"
			}
		],
		"name": "allPositions",
		"outputs": [
			{
				"components": [
					{
						"internalType": "int256",
						"name": "size",
						"type": "int256"
					},
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "margin",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "open",
						"type": "bool"
					},
					{
						"internalType": "uint256",
						"name": "closePrice",
						"type": "uint256"
					}
				],
				"internalType": "struct PerpDEX.Trade[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "assetName",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "assetPrice",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "balances",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_trader",
				"type": "address"
			}
		],
		"name": "checkPosition",
		"outputs": [
			{
				"internalType": "int256",
				"name": "",
				"type": "int256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_trader",
				"type": "address"
			}
		],
		"name": "checkTrader",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "fundingRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "int256",
				"name": "num",
				"type": "int256"
			}
		],
		"name": "getAbsoluteValue",
		"outputs": [
			{
				"internalType": "int256",
				"name": "",
				"type": "int256"
			}
		],
		"stateMutability": "pure",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "margin",
		"outputs": [
			{
				"internalType": "int256",
				"name": "",
				"type": "int256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "priceFeedAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

let address = "0xad34E3b78ECD72A019c290de0D0935A46E3003bD";

const token_ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_symbol",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "_initialSupply",
				"type": "uint256"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "dexAdress",
				"type": "address"
			}
		],
		"name": "approveContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "buyTokens",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "subtractedValue",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "redeemTokens",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "newBuyPrice",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "newRedeemPrice",
				"type": "uint256"
			}
		],
		"name": "setPrices",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "fallback"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "withdrawEther",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "buyPrice",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "redeemPrice",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupplyLimit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];
const token_address = "0xB64075a5D8326Ceb26f66f3615FB066a9b2b498E";

class Home extends Component {

	state = {
		walletAddress: "",
		_approved: '',
		_amount: '',
		_allPositionsFront: [],
		_buy: '',
		_buybox: 0,
		statusError: false,
		statusLoading: false,
		success: false,
		_buyAmount: ''
	}

	async componentDidMount() {
		if (localStorage?.getItem('isWalletConnected') === 'true') {

			if (typeof window.ethereum !== 'undefined') {
				console.log('MetaMask is installed!');

				try {

					web3mm = new Web3(window.ethereum);
					await window.ethereum.request({ method: 'eth_requestAccounts' });
					accounts = await web3mm.eth.getAccounts();
					account = accounts[0];

					contract = new web3mm.eth.Contract(ABI, address);
					token_contract = new web3mm.eth.Contract(token_ABI, token_address);

					this.setState({ walletAddress: account });

					const chainId = 5;

					if (window.ethereum.networkVersion !== chainId) {
						try {
							await window.ethereum.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3mm.utils.toHex(chainId) }],
							});
						} catch (err) {

							if (err.code === 4902) {
								await window.ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [
										{

											chainName: 'Goerli Test Network',
											chainId: web3mm.utils.toHex(chainId),
											nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
											rpcUrls: ['https://goerli.infura.io/v3/'],

										},
									],
								});
							}
						}
					}


					try {
						localStorage.setItem('isWalletConnected', true);

						let allowance = await token_contract.methods.allowance(account, address).call();

						if (allowance >= usdtAmount) {
							this.setState({ _approved: true });
							console.log("true Allowance:" + allowance);


						} else {
							this.setState({ _approved: false });
							console.log("false Allowance:" + allowance);

						}


					} catch (ex) {
						console.log(ex)
					}

				} catch (err) {
					console.log("err: " + err);
				}
			}
		}

	}

	walletConnect = async event => {
		event.preventDefault();

		if (typeof window.ethereum !== 'undefined') {
			console.log('MetaMask is installed!');

			try {

				web3mm = new Web3(window.ethereum);
				await window.ethereum.request({ method: 'eth_requestAccounts' });
				accounts = await web3mm.eth.getAccounts();
				account = accounts[0];

				contract = new web3mm.eth.Contract(ABI, address);
				token_contract = new web3mm.eth.Contract(token_ABI, token_address);

				this.setState({ walletAddress: account });

				const chainId = 5;

				if (window.ethereum.networkVersion !== chainId) {
					try {
						await window.ethereum.request({
							method: 'wallet_switchEthereumChain',
							params: [{ chainId: web3mm.utils.toHex(chainId) }],
						});
					} catch (err) {

						if (err.code === 4902) {
							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{

										chainName: 'Goerli Test Network',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
										rpcUrls: ['https://goerli.infura.io/v3/'],

									},
								],
							});
						}
					}
				}


				try {
					localStorage.setItem('isWalletConnected', true);

					let allowance = await token_contract.methods.allowance(account, address).call();

					if (allowance >= usdtAmount) {
						this.setState({ _approved: true });
						console.log("true Allowance:" + allowance);


					} else {
						this.setState({ _approved: false });
						console.log("false Allowance:" + allowance);

					}





				} catch (ex) {
					console.log(ex)
				}

			} catch (err) {
				console.log("err: " + err);
			}

		}

	}

	approve = async event => {
		event.preventDefault();

		try {

			try {

				console.log("account : " + this.state.walletAddress);
				let allowance = await token_contract.methods.allowance(this.state.walletAddress, address).call();

				if (allowance > 0) {

					await token_contract.methods.increaseAllowance(address, usdtAmount).send({ /*maxPriorityFeePerGas: 400000000000,*/ gasLimit: 685000, from: account });
					this.setState({ _approved: true });
					window.location.reload(true);
				} else {

					await token_contract.methods.approve(address, usdtAmount).send({ /*maxPriorityFeePerGas: 400000000000,*/gasLimit: 685000, from: account });
					this.setState({ _approved: true });
					window.location.reload(true);
				}



			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);

			}

		} catch (err) {

			console.log(err);

		}


	}

	trade = async event => {
		event.preventDefault();



		try {

			await contract.methods.trade(this.state._amount).send({ gasLimit: 385000, from: account });

		} catch (err) {
			console.log(err);
		}


	}

	async closePosition(index) {


		try {

			console.log("index:" + index);

			await contract.methods.closePosition(index).send({ gasLimit: 385000, from: account });

		} catch (err) {
			console.log(err);

		}


	}

	allPositions = async event => {
		event.preventDefault();

		try {


			allPositionsFront = await contract.methods.allPositions(account).call();
			this.setState({ _allPositionsFront: allPositionsFront });
			console.log(this.state._allPositionsFront);


		} catch (err) {
			console.log(err);
		}

	}

	buy = async event => {
		event.preventDefault();

		try {

			this.setState({ statusError: false, statusLoading: true });
			buy = await token_contract.methods.buyTokens(this.state._buyAmount).send({ gasLimit: 385000, from: account, value: 100000000000000 * this.state._buyAmount});
			this.setState({ _buy: buy });

			this.setState({ statusLoading: false, success: true });
			await new Promise(resolve => setTimeout(resolve, 5000));
			window.location.reload(true);

		} catch (err) {
			this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
			console.log(err);
		}

	}

	closeWindow = async event => {
		event.preventDefault();

		this.setState({ _buybox: 0 });
	}

	buyOpen = async event => {
		event.preventDefault();

		this.setState({ _buybox: 1 });
	}


	walletDisconnect = async event => {
		event.preventDefault();

		this.setState({ walletAddress: '' });

		try {
			localStorage.setItem('isWalletConnected', false);
		} catch (ex) {
			console.log(ex);
		}

	}

	render() {

		return (

			<div class="allWrap">
				<div class="light">
					<div class="cont">

						<div class="headers">

							<div class="headers2">

								<div class="navBar">

								</div>
								<div class="right">

									<div class="connect2">
										{this.state.walletAddress === '' ?
											(<form onSubmit={this.walletConnect}>
												<button class="wallet2"><img class="walletImg" src={wallet} />Wallet Connect</button>
											</form>) : (<form onSubmit={this.walletDisconnect}><button class="wallet2" >
												{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

									</div>
									<p></p>
									<div class="connect2">
										<button class="wallet2" onClick={this.buyOpen}>Buy Tokens</button>
									</div>

								</div>

							</div>

						</div>

						<div class="introduction">

							<div class="in2">
								<div class="intro">
									Perp DEX
								</div>
							</div>
							{this.state._approved === true ?
								(<div class="btn3">
									<div class="input1Div">
										<input class="input1" type="numbers" name="trade" placeholder="amount" value={this.state._amount}
											onChange={event => this.setState({ _amount: event.target.value })}></input>

										<button onClick={this.trade} class="btnfos-0-3" type="submit">Trade</button>
									</div>
									<button onClick={this.allPositions} class="btnfos-0-3" type="submit">All Positions</button>


								</div>) : (<button onClick={this.approve} class="btnfos-0-3" type="submit">Approve</button>)}

						</div>


					</div>
					<div class="tokenIds" >
						{this.state._allPositionsFront.map((element1) => {

							console.log("ID :" + element1[0]);
							console.log("Time :" + element1[1]);


							return (
								<div class="tokenIds2">
								</div>);

						})}

						<table>
							<thead>
								<tr>
									<th>Size</th>
									<th>Price</th>
									<th>Margin</th>
									<th>Open</th>
									<th>Close Price</th>
								</tr>
							</thead>
							<tbody>
								{this.state._allPositionsFront.map((element1, index) => (


									<tr key={element1[0][0]}>
										{/* Assuming element1 is an array with data you want to display */}
										<td>{element1[0]}</td>
										<td>{element1[1]}</td>
										<td>{element1[2]}</td>
										<td>{element1[3] ? "true" : "false"}</td>
										<td>{element1[4]}</td>
										<td><button onClick={() => this.closePosition(index)} class="close">Close</button></td>
									</tr>
								))}
							</tbody>
						</table>

					</div>

					{this.state._buybox > 0 ?
						(<form onSubmit={this.buy}>
							<div class="formMain1">
								<div class="formMain">
									<form onClick={this.closeWindow}>
										<button class="closeNote">✖</button>
									</form>
									<div>

										<div class="formCon">
											<div>Enter token amount</div>
											<input class="input2" type='number' required placeholder='100' value={this.state._buyAmount}
												onChange={event => this.setState({ _buyAmount: event.target.value })}></input>
										</div>
										{this.state.walletAddress === '' ?

											(<button class="buyBtn" onClick={this.walletConnect}>CONNECT</button>) :
											(<button class="buyBtn" type='submit'>BUY</button>)}
										<div>

											{this.state.statusError ? (
												<div class="loadingContainer">
													<div class="error-2">Error. Retry later</div>
												</div>)
												: null}

											{this.state.statusLoading ? (
												<div class="loadingContainer">
													<div>
														<div class="loadingText-2">Buying</div>
													</div>
												</div>)
												: null}

											{this.state.success ? (

												<div class="loadingContainer">
													<div class="success-2">Tokens bought successfully</div>
												</div>)
												: null}

										</div>
									</div>

								</div>

							</div></form>) : null}

				</div >
			</div >)
	}
}

export default Home;
